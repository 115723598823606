import React from "react"
import ContactList from "../AddressList/contactlist"
import {CardDefault, CardTitle, CardBody} from "../AddressListStyle"

const ContactCard = () => {
  return (
    <CardDefault className="card">
      <CardTitle className="card-title">Address</CardTitle>
      <CardBody className="card-body">
        <ContactList />
      </CardBody>
    </CardDefault>
  )
}

export default ContactCard
