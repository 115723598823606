import * as React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {Section, Container, SectionImageBkg, BreadCrumb} from "../components/Section"
import { HeroBanner, HeroTitle, HeroCaption, HeroContent, HeroLeft, HeroRight, HeroCard } from "../components/HeroBanner"
import JoinForm from "../components/JoinForm"
import ContactCard from "../components/AddressCard/contact"
import CategoryCarousel from "../components/CategoryCarousel"


const SectionLocation = styled.div`
  position:relative;
  padding:40px 15px; 
  @media(min-width:992px){
    padding:60px 30px; 
  }
  @media(min-width:1200px){
    padding:80px 30px; 
  }
  @media(min-width:1600px){
    padding:120px 30px; 
  }
  .card{
    margin-right:auto;
    margin-left:inherit;
  }

`
const MapImage = styled(SectionImageBkg)`
  overflow:hidden;
  border-radius:200px 0 0 40px;
  width:calc(100% - 200px);
  right: 0;
  left: auto;
`
const SectionMap = styled(Section)`
  .container{
    max-width: inherit;
    padding-left: calc((100% - 1410px) / 2);
    padding-right: 0;
  }
`
const AddressBlockList = styled.div`  
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  margin:0;
  @media(min-width:768px){       
    margin:0 -10px;
  }
  @media(min-width:992px){       
    margin:0 -15px;
  }
`
const Address = styled.address`
  position:relative ;
  background: #f3f6fb;
  background: -moz-linear-gradient(top,  #f3f6fb 0%, #eaeff7 100%);
  background: -webkit-linear-gradient(top,  #f3f6fb 0%,#eaeff7 100%);
  background: linear-gradient(to bottom,  #f3f6fb 0%,#eaeff7 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f3f6fb', endColorstr='#eaeff7',GradientType=0 );
  width: 100%;
  margin:0 auto;
  border:3px solid #fff;
  transition:all 0.5s ease;
  font-style: inherit;
  margin-top: 15px;
  border-radius:20px;
  padding:20px;
  &:hover{
    box-shadow: 0 30px 30px rgba(190,197,207,0.60);
  }
  @media(min-width:768px){       
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;

    padding: 30px 20px;
    margin:0 10px;
  }
  @media(min-width:992px){    
    border-radius:30px;
    
    padding:50px 30px;
    margin:0 15px;
  }
  > strong {
    display: block;
    color: #ff4848;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    @media(min-width:768px){ 
      font-size: 18px;
      line-height: 28px;
    }
    @media(min-width:768px){ 
      font-size: 22px;
      line-height: 32px;
    }
    @media(min-width:992px){    
      font-size:28px;
      line-height:38px;
      font-weight:900;
    }
  }
  + address {
    border-top: 1px solid #ebedf3;
    @media(min-width:768px){           
      border-top:none;
      border-left:1px solid #EBEDF3;
   }
  }
`
const ListInfo = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  font-size:16px;
  line-height:34px;
  @media(min-width:992px){            
    font-size:18px;
    line-height:36px;
    font-weight:700;
  }
`
const ListInfoItem = styled.li`
  margin: 0;
  padding: 0;
  + li {
    margin-top: 15px;
  }
  .label {
    color: #595959;
    font-weight: 500;
    display: block;
    min-width: 75px;
    font-size: 14px;
    line-height: 18px;
    @media(min-width:576px){
      font-size:16px;
      line-height:20px;
      display: inline-block;
    }
    @media(min-width:992px){
      min-width: 80px;
    }
  }
  strong {
    color: #000;
  }
  a {
    color: #000;
    font-weight: 700;
    &:hover {
      color:#ff4848;
    }
    + span {
      margin-left: 5px;
      @media(min-width:992px){
        margin-left: 10px;
      }
    }
  }
`
const BadgeStatic = styled.span`
  background-color: #444;
  color: #fff;
  display: inline-block;
  border-radius: 12px;
  font-size: 10px;
  line-height: 16px;
  padding: 2px 8px;
  font-weight: 400;
  @media(min-width:992px){
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    padding: 4px 14px;
  }
`
const ContactUsPage = ({ data, location }) => {
  const pageData = data.contentfulPageContactUs
  const allCategories = data.allContentfulCategory.edges  
  return(
    <Layout location={location}>
      <Seo title={pageData.metaTitle} description={pageData.metaDescription} />
      <Section bgColor="#dbe1e9" bgBefore="#002765" bgBeforeHeight="80%"  bgBeforeWidth="100%">
          <HeroBanner className="banner-hero" bgBefore="block" data-shadowtext={pageData.heroTitle}>
              <HeroCaption>
              <Container maxWidth="1640px">
                <BreadCrumb><Link to='/'>Home</Link><span>Contact Us</span></BreadCrumb>
                  <HeroContent>
                  <HeroLeft><HeroTitle>{pageData.heroTitle}</HeroTitle></HeroLeft>
                  <HeroRight><HeroCard><JoinForm location={location} /></HeroCard></HeroRight>
                  </HeroContent>
              </Container>
              </HeroCaption>
          </HeroBanner>
      </Section>
      <Section ept="80px" epb="80px" xpt="80px" xpb="80px" pt="60px" pb="60px" bgColor="#dbe1e9" className="section-customer">        
        <Container>        
          <div dangerouslySetInnerHTML={{__html: pageData.content.childMarkdownRemark.html}} />
          <AddressBlockList>
              <Address>
                <strong>SALES DEPARTMENT</strong>
                <ListInfo>
                  <ListInfoItem>
                    <div className="label">Toll Free :</div>
                    <a href="tel:8332033426" aria-label="phone number">(833) 203-3426</a>{" "}
                    <BadgeStatic>New Customers</BadgeStatic>
                  </ListInfoItem>
                  <ListInfoItem>
                    <div className="label">Local :</div>
                    <a href="tel:8067314816" aria-label="phone number">(806) 731-4816</a>
                  </ListInfoItem>
                  <ListInfoItem>
                    <div className="label">Email :</div>
                    <a href="mailto:info@panhandlemetals.com">info@panhandlemetals.com</a>
                  </ListInfoItem>
                </ListInfo>
              </Address>
              <Address>
                <strong>CUSTOMER SERVICE</strong>
                <ListInfo>
                  <ListInfoItem>
                    <div className="label">Toll Free :</div>
                    <a href="tel:8002275846" aria-label="phone number">(800) 227-5846</a>{" "}
                    <BadgeStatic>Existing Customers</BadgeStatic>
                  </ListInfoItem>
                  <ListInfoItem>
                    <div className="label">Local :</div>
                    <a href="tel:8065533026" aria-label="phone number">(806) 553-3026</a>
                  </ListInfoItem>
                  <ListInfoItem>
                    <div className="label">Email :</div>
                    <a href="mailto:customerservice@panhandlemetals.com">customerservice@panhandlemetals.com</a>
                  </ListInfoItem>
                </ListInfo>
              </Address>
            </AddressBlockList>
        </Container>        
      </Section>
      

      <SectionMap ept="0" epb="120px" xpt="0" xpb="120px" pt="0" pb="80px" bgColor="#dbe1e9"  className="section-map">
        <Container className="container">
          <SectionLocation>
            <MapImage><StaticImage src="../images/map-address.png" alt="map-address" /></MapImage>
            <ContactCard />
          </SectionLocation>
        </Container>
      </SectionMap>

      <Section ept="120px" epb="120px" xpt="80px" xpb="80px" pt="40px" pb="40px" bgColor="#fff"  className="section-buy">
        <SectionImageBkg height="50%" bgColor="#161420" opacityImg="0.3"><StaticImage src="../images/black-vector.jpg" alt="black-vector" /></SectionImageBkg>
        <CategoryCarousel data={allCategories} current="all" />
      </Section>
    </Layout>
  )
}


export default ContactUsPage

export const pageQuery = graphql`
  query ContactUsPageQuery {
    contentfulPageContactUs {
      metaTitle
      metaDescription
      heroTitle
      content {
        childMarkdownRemark {
          html
        }
      }
      phoneNumerCta {
        number
        href
      }
    }
    allContentfulCategory {
      edges {
        node {
          name
          url
          mainImage {
            gatsbyImageData(placeholder: BLURRED, quality: 90)
          }
        }
      }
    }
  }
`